import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

// Base Page
import BasePage from '@/pages/Base'

// Services
import PortService from '@/services/Port'
import CountryService from '@/services/Country'
import ProvinceService from '@/services/Province'
import CityService from '@/services/City'
import moment from 'moment'

@Component({})
export default class PortList extends BasePage {
  constructor() {
    super()
  }

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Type',
      align: 'left',
      sortable: true,
      value: 'port_type'
    },
    {
      text: 'Country',
      align: 'left',
      value: 'countryName'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  portDialog: boolean = false
  isEdit: boolean = false
  portDataDialog: any = {}

  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  typeItems: any[] = ['Airport','Seaport']

  // provinces: any = {
  //   items: [],
  //   keyword: null,
  //   loading: false,
  //   selected: null
  // }

  // cities: any = {
  //   items: [],
  //   keyword: null,
  //   loading: false,
  //   selected: null
  // }

  selectedDelete: any = {}
  selectedIdPort: number = 0
  selectedCountryPort: number = null
  showDeleteModal: boolean = false

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getPorts()
    this.getCountries()
  }

  // async onChangedCountry() {
  //   this.getProvinces()
  // }

  // async onChangedProvince() {
  //   this.getCities()
  // }

  onReset(){
    this.searchInput = ''
    this.selectedCountryPort = null
    this.$vuetify.goTo(0)
    this.getPorts()
    this.getCountries()
  }

  async onClearSearch() {
    this.searchInput = ''
  }

  async getPorts() {
    try {
      this.tableLoading = true
      const opts :any = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          include: 'country'
        }
      }
      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.searchInput
        }
      }
      if (this.selectedCountryPort != null) {
        opts.params = {
          ...opts.params,
          'filter[country_id][is]': this.selectedCountryPort
        }
      }
      //  else {
      //   var textFilter = 'filter[name][like]'
      //   delete opts.params[textFilter]
      // }
      const response = await PortService.getPort(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta
      const responseIncluded = response.data.included
      this.tableItems = []
      forEach(responseData, dataProvince => {
        const countryName = responseIncluded.country[dataProvince.relationships.country.id].attributes.name
        this.tableItems.push({...dataProvince.attributes, countryName: countryName})
      })
      // console.log('this.tableItems', this.tableItems)
      this.tableTotalItems = responseMeta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getCountries() {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 300
        }
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries.items = []
      for (const dataCountry of responseData) {
        this.countries.items.push(dataCountry.attributes)
      }
      this.countries.items = sortBy(this.countries.items, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }
  // async getProvinces() {
  //   try {
  //     this.provinces.loading = true
  //     const opts: any = {
  //       params: {
  //         'page[num]': 1,
  //         'page[limit]': 253
  //       }
  //     }
  //     if (this.portDataDialog.country_id) {
  //       opts.params = {
  //         ...opts.params,
  //         'filter[country_id][is]': this.portDataDialog.country_id,
  //       }
  //     } else {
  //       var textFilter = 'filter[country_id][is]'
  //       delete opts.params[textFilter]
  //     }
  //     const response = await ProvinceService.getProvinces(opts)
  //     const responseData = response.data.data
  //     this.provinces.items = []
  //     for (const dataProvince of responseData) {
  //       this.provinces.items.push(dataProvince.attributes)
  //     }
  //     this.provinces.items = sortBy(this.provinces.items, 'name')
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.provinces.loading = false
  //   }
  // }
  // async getCities() {
  //   try {
  //     this.cities.loading = true
  //     const opts: any = {
  //       params: {
  //         'page[num]': 1,
  //         'page[limit]': 253,
  //       }
  //     }
  //     if (this.portDataDialog.province_id) {
  //       opts.params = {
  //         ...opts.params,
  //         'filter[province_id][is]': this.portDataDialog.province_id,
  //       }
  //     } else {
  //       var textFilter = 'filter[province_id][is]'
  //       delete opts.params[textFilter]
  //     }
  //     const response = await CityService.getCities(opts)
  //     const responseData = response.data.data
  //     this.cities.items = []
  //     for (const dataCity of responseData) {
  //       this.cities.items.push(dataCity.attributes)
  //     }
  //     this.cities.items = sortBy(this.cities.items, 'name')
  //   } catch (error) {
  //     this.catchHandler(error)
  //   } finally {
  //     this.cities.loading = false
  //   }
  // }

  openCreateDialog() {
    this.$validator.reset()
    this.isEdit = false
    this.countries.items = []
    // this.provinces.items = []
    // this.cities.items = []
    this.portDataDialog = {
      name: null,
      port_type: null,
      country_id: null
      // province_id: null,
      // city_id: null,
    }
    this.portDialog = true
    this.getCountries()
  }

  closeDialog() {
    this.portDialog = false
  }

  async addPort() {
    try {
      const validationResponse = await this.$validator.validateAll('portDialogScope')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        this.portDialog = false
        const payload = {
          name: this.portDataDialog.name,
          port_type: this.portDataDialog.port_type,
          // city_id: this.portDataDialog.city_id
          country_id: this.portDataDialog.country_id
        }
        await PortService.setPort(payload)
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.getPorts()
      }else{
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  async deletePort() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await PortService.deletePort(this.selectedDelete.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getPorts()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openEditDialog(props) {
    this.isEdit = true
    this.selectedIdPort = props.item.id
    this.portDataDialog = {
      name: props.item.name,
      port_type: props.item.port_type,
      country_id: props.item.country_id
      // province_id: props.item.province_id,
      // city_id: props.item.city_id,
    }
    this.getCountries()
    this.portDialog = true
  }

  async updatePort() {
    try {
      const validationResponse = await this.$validator.validateAll('portDialogScope')
      if (validationResponse) {
        this.showLoading({ text: 'Saving...' })
        this.portDialog = false
        const payload = {
          name: this.portDataDialog.name,
          port_type: this.portDataDialog.port_type,
          country_id: this.portDataDialog.country_id
        }
        await PortService.updatePort(this.selectedIdPort, payload)
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.getPorts()
      }else{
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
